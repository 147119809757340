'use client';

import { COOKIE_DOMAIN, DEFAULT_ULTRACARD_COOKIE_DAYS } from '../constants';
import { mergeItemsInCart } from '../domain/domain.utils';
import { createCookie } from '../../utils/cookie/createCookie';
import type UcCartItem from './types/UcCartItem';

type ErrorCallback = (error: string) => void;

type SuccessCallback = (data: any) => void;

declare global {
  interface Window {
    uc: any;
  }
}

class UltraCartService {
  private static currentState: any = null;

  private static checkoutApi() {
    console.log('checkoutApi');

    if (typeof window === 'undefined' || !window?.uc?.checkoutApi) {
      // console.error('window undefined');

      return undefined;
    }

    return window.uc.checkoutApi;
  }

  public static getItems(): UcCartItem[] {
    const result =
      window?.uc?.cart && window?.uc?.cart && window?.uc?.cart?.items
        ? window.uc.cart.items
        : undefined;
    return result;
  }

  public static getCart(
    expand: Record<string, string>,
    onSuccess: SuccessCallback,
    onError: ErrorCallback,
    cartId?: string
  ) {
    const checkoutApi = this.checkoutApi();

    if (!checkoutApi) {
      onError('CheckoutApi undefined');

      return;
    }

    if (cartId) {
      checkoutApi.getCartByCartId(cartId, expand, (error: any, data: any, response: any) => {
        this.baseHandler(error, data, response, onError, onSuccess, true);
      });
    } else {
      checkoutApi.getCart(expand, (error: any, data: any, response: any) => {
        createCookie(
          window.uc.cookieName,
          data.cart.cart_id,
          DEFAULT_ULTRACARD_COOKIE_DAYS,
          COOKIE_DOMAIN
        );

        this.baseHandler(error, data, response, onError, onSuccess, false);
      });
    }
  }

  public static addToCart(
    newItemToCart: any,
    onSuccess: () => void,
    onError: (error: string) => void
  ) {
    const existedItemsInCart = this.getItems();

    if (!existedItemsInCart) {
      return;
    }

    const updatedCartItems = mergeItemsInCart(existedItemsInCart, newItemToCart);

    const ucCopy = {
      ...window.uc.cart,
      items: updatedCartItems,
    };

    UltraCartService.updateUcCart(ucCopy, { expand: 'items' }, onSuccess, onError, true);
  }

  public static updateUcCart(
    cart: any,
    expand: Record<string, string>,
    onSuccess: SuccessCallback,
    onError: ErrorCallback,
    forceNotify = false
  ) {
    this.checkoutApi().updateCart(cart, expand, (error: any, data: any, response: any) => {
      this.baseHandler(error, data, response, onError, onSuccess, true, forceNotify);
    });
  }

  private static baseHandler(
    error: any,
    data: any,
    response: any,
    onError: ErrorCallback,
    onSuccess: SuccessCallback,
    existedCart: boolean,
    forceNotify = false
  ) {
    if (error || data.errors) {
      if (data && data.errors && data.errors.length > 0) {
        onError(data.errors[0]);
      }

      if (response && response.text) {
        onError(response.text);
      } else {
        onError(error);
      }

      return;
    }

    window.uc.cart = data.cart;

    if (this.isStateChanged(data.cart)) {
      const beforeStateNull = this.currentState === null;

      this.currentState = { ...data.cart };

      onSuccess({ data, existedCart });

      if (forceNotify) {
        this.notifySubscribers(false);
      } else {
        this.notifySubscribers(true);
      }
    } else {
      onSuccess({ data, existedCart });
    }
  }

  public static forceNotify() {
    this.subscribers.forEach((callback) => callback(true));
  }

  public static subscribe(callback: (beforeStateNull: boolean) => void): void {
    this.subscribers.push(callback);
  }

  public static unsubscribe(callback: (beforeStateNull: boolean) => void): void {
    this.subscribers = this.subscribers.filter((sub) => sub !== callback);
  }

  private static subscribers: any[] = [];

  private static notifySubscribers(beforeStateNull: boolean): void {
    this.subscribers.forEach((callback) => callback(beforeStateNull));
  }

  private static isStateChanged(newState: any) {
    if (this.currentState === null) return true;

    const isItemsLengthSame = (this.currentState?.items?.length ?? 0) === newState.items.length;

    const isBaseCurrencyCodeSame =
      this.currentState.base_currency_code === newState.base_currency_code;

    const isCartIdSame = this.currentState.cart_id === newState.cart_id;

    const isMerchantIdSame = this.currentState.merchant_id === newState.merchant_id;

    return !(isItemsLengthSame && isBaseCurrencyCodeSame && isCartIdSame && isMerchantIdSame);
  }

  /**
   * Finalizes the order using the UltraCart Browser SDK's finalizeOrder method
   * @param cartModel The cart object containing items and customer details
   * @param onSuccess Callback executed on successful order placement
   * @param onError Callback executed if an error occurs
   */
  public static placeOrder(
    cartModel: any,
    onSuccess: (data: any) => void,
    onError: (error: string) => void
  ) {
    const checkoutApi = this.checkoutApi();

    if (!checkoutApi) {
      console.error('Checkout API is not available.');

      onError('Checkout API is not available.');

      return;
    }

    checkoutApi.updateCart(cartModel, { expand: 'items' }, (error: any, data: any) => {
      if (error || (data && data.errors)) {
        // Handle errors
        const errorMessage = error || data.errors[0]?.user_message || 'Error updating cart.';

        console.error(errorMessage);

        onError(errorMessage);

        return;
      }

      console.log('Cart updated successfully:', data.cart);

      onSuccess(data.cart);
    });
  }
}

export default UltraCartService;
